<template>
  <div>
    <a-modal
      v-model="visible"
      :afterClose="handleCancel"
      :title="'Data ' + title"
      :forceRender="true"
      :footer="null"
      :width="this.columns.length > 7 ? 1500 : 800"
      :maskClosable="true"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="handleCancel">
          Cancel
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submit('ruleForm')"
        >
          Submit
        </a-button>
      </template>
      <div class="row">
        <div class="col-md-12">
          <a-button class="editable-add-btn" @click="handleAdd">
            Add
          </a-button>
          <a-table
            rowKey="id"
            tableLayout="auto"
            :columns="columns"
            :dataSource="datatable"
            :loading="loading"
            size="small"
            :pagination="{
              defaultPageSize: 20,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
              hideOnSinglePage: true,
            }"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.title}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                {{ text }}
              </template>
            </template>
            <template
              v-for="col in ['jenis', 'value', 'keterangan']"
              :slot="col"
              slot-scope="text, record, index, column"
            >
              <div :key="col">
                <a-input
                  v-if="record.editable || record.createable"
                  style="margin: -5px 0;"
                  :placeholder="`Masukan ${column.className}`"
                  :ref="'ref' + record.key + col"
                  :value="text"
                  @keyup.enter="() => save(record.key)"
                  @change="(e) => handleChange(e.target.value, record.key, col)"
                />
                <template v-else>
                  <span v-if="col === 'value'">
                    {{ column.className !== 'Jarak' ? numberFormat(text) : text }}
                  </span>
                  <span v-else>{{ text }}</span>
                </template>
              </div>
            </template>
            <template slot="operation" slot-scope="text, record, index">
              <div class="editable-row-operations">
                <span v-if="record.editable || record.createable">
                  <a class="text-primary" @click="() => save(record.key)"
                    >Simpan</a
                  >
                  <a-divider type="vertical" />
                  <a class="text-danger" @click="() => cancel(record.key)"
                    >Batal</a
                  >
                </span>
                <span v-else>
                  <a
                    class="text-warning"
                    :disabled="editingKey !== ''"
                    @click="() => edit(record.key)"
                    >Edit</a
                  >
                  <a-divider type="vertical" />
                  <a-popconfirm
                    title="Yakin ingin hapus?"
                    @confirm="() => remove(index)"
                  >
                    <a class="text-danger" :disabled="editingKey !== ''"
                      >Hapus</a
                    >
                  </a-popconfirm>
                </span>
              </div>
            </template>
            <template slot="upload" slot-scope="text, record">
              <img
                class="preview"
                v-if="record.imageUrl"
                :src="record.imageUrl"
                @click="lihatgambar(record)"
              />
            </template>
            <!-- <span slot="operation" slot-scope="text, record">
                <a-divider type="vertical" />
                <i
                  class="fa fa-pencil-square-o text-warning"
                  aria-hidden="true"
                  @click="sendToForm('update', record, record.id)"
                ></i>
                <a-divider type="vertical" />
              </span> -->
            <span slot="tgl" slot-scope="text, record">
              {{ formatDate(record.tgl) }}
            </span>
            <span slot="numberformat" slot-scope="text">
              {{ numberFormat(text) }}
            </span>
            <!-- <template slot="footer">
              <indcate-print :title="title"/>
              <a class="text-primary" @click="printdata">Export {{ title }}</a>
            </template> -->
          </a-table>
        </div>
      </div>
    </a-modal>
    <zoomgambar ref="zoomgambar" />
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import zoomgambar from '@/components/lou/zoomgambar.vue'

export default {
  components: {
    zoomgambar,
  },
  data() {
    return {
      title: '',
      columns: [],
      columntambahan: '',
      lastindex: '',
      datatable: [],
      datatablename: '',
      editingKey: '',
      table: [],
      roletags: false,
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      visible: false,
      loading: false,
      busy: false,
      datenow: null,
    }
  },
  created() {
    this.datenow = lou.datenow()
  },
  methods: {
    moment,
    seething(column) {
      // console.log('column', column)
    },
    handleAdd() {
      var uniq = 'id' + new Date().getTime()
      const { count, datatable } = this
      var newData = {}
      newData.key = uniq
      this.columns.forEach((element) => {
        if (element.dataIndex !== 'operation') {
          newData[element.dataIndex] = ''
        }
      })
      newData.createable = true
      this.editingKey = uniq
      // console.log('this.$refs', this.$refs['ref' + uniq + 'jenis'])
      this.datatable = [...datatable, newData]
      this.count = count + 1
    },
    showModal(title, columns, table, datatablename, columntambahan = '') {
      this.visible = true
      // console.log('table, columns, title', table, columns, title)
      this.title = title
      this.columns = columns
      this.columns.push({
        title: 'operation',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
      })
      this.columntambahan = columntambahan
      this.datatable = table
      this.datatablename = datatablename
    },
    handleCancel() {
      console.log('cancel')
      this.visible = false
      this.$parent[this.datatablename] = this.datatable
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    printdata() {
      console.log('print')
      if (this.datatable.length !== 0) {
        lou.printrekap(this.columns, this.datatable, this.title)
      } else {
        this.$notification.error({
          message: 'Failed!',
          description: 'Data is still empty!',
        })
      }
    },
    // Format date func
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    lihatgambar(record) {
      this.$refs.zoomgambar.lihatgambar(record)
    },
    handleChange(value, key, column) {
      const newData = [...this.datatable]
      const target = newData.filter((item) => key === item.key)[0]
      if (target) {
        target[column] = value
        this.datatable = newData
      }
    },
    edit(key) {
      console.log('key', key)
      const newData = [...this.datatable]
      const target = newData.filter((item) => key === item.key)[0]
      this.editingKey = key
      if (target) {
        target.editable = true
        this.datatable = newData
      }
    },
    save(key) {
      const newData = [...this.datatable]
      const target = newData.filter((item) => key === item.key)[0]
      if (target) {
        if (target.createable) {
          var val = Object.keys(target)
          var stillEmpty = false
          // Check if value still empty
          val.forEach((element) => {
            if (
              element !== 'key' ||
              element !== 'editable' ||
              element !== 'createable'
            ) {
              if (target[element] === '') {
                // console.log(element, target[element])
                stillEmpty = true
              }
            }
          })
          if (stillEmpty) {
            lou.shownotif('Valid', 'Input tidak boleh kosong!')
          } else {
            delete target.createable
            this.datatable = newData
          }
        } else {
          delete target.editable
          this.datatable = newData
        }
      }
      this.editingKey = ''
    },
    remove(key) {
      this.datatable.splice(key, 1)
    },
    cancel(key) {
      const newData = [...this.datatable]
      const target = newData.filter((item) => key === item.key)[0]
      this.editingKey = ''
      if (target) {
        if (target.createable) {
          const indexTarget = newData.findIndex((item) => key === item.key)
          this.datatable.splice(indexTarget, 1)
        } else {
          delete target.editable
          this.datatable = newData
        }
      }
    },
    numberFormat(val) {
      return lou.curency(val)
    },
  },
}
</script>

<style>
img.preview {
  width: 50px;
  height: 50px;
}
/* Style the Image Used to Trigger the Modal */
img.preview {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

img.preview:hover {
  opacity: 0.7;
}
</style>
